const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="14"
    viewBox="0 0 9 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 2.10303L2 0.603027L8.5 7.10303L2 13.603L0.5 12.103L5.5 7.10303L0.5 2.10303Z"
      fill="#008254"
    />
  </svg>
);

export default Arrow;
