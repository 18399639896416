'use client';
import React from 'react';
import { EXPERIMENTS } from '../experiments';
import {
  createClient,
  StructuredContentProvider,
} from '@nerdwallet/structured-content-core';
import components from '~/app/lib/sc-components';
import dynamic from 'next/dynamic';
import schema from '@nerdwallet/structured-content/schema';
import { useInitializeGtm } from '../lib/initializeGtm';
import { getPageTaxonomy } from '../lib/page-taxonomy';
import { usePathname } from 'next/navigation';
import { useExperiment } from '@nerdwallet/features';
import BounceTracking from '../components/bounce-tracking';
import SegmentTracking from '../lib/segment/SegmentTracking';
import FrontPageControl from './variants/front-page-control';
import FrontPageVariantOne from './variants/front-page-variant-one';
import FrontPageVariantTwo from './variants/front-page-variant-two';

const FrontPageMainWrapper = ({
  assignedVariantName,
}: {
  assignedVariantName?: string;
}): JSX.Element => {
  const client = createClient({
    components: components as any,
    schema,
    codesplittingFunction: (asyncImport, exportName) =>
      dynamic(() =>
        asyncImport.then((mod) => ({
          default: mod[exportName],
        })),
      ),
  });

  useInitializeGtm({ pageTaxonomy: getPageTaxonomy(usePathname()) ?? [] });

  const { VARIANT_ONE, VARIANT_TWO } =
    EXPERIMENTS.homepageReorderExperiment.variants;

  const isVariantOne = assignedVariantName === VARIANT_ONE;
  const isVariantTwo = assignedVariantName === VARIANT_TWO;
  const isControl = !isVariantOne && !isVariantTwo;

  return (
    <React.Fragment>
      <BounceTracking />
      <SegmentTracking segmentPageName={`homepage_${assignedVariantName}`}>
        <StructuredContentProvider client={client}>
          {isControl && <FrontPageControl />}
          {isVariantOne && <FrontPageVariantOne />}
          {isVariantTwo && <FrontPageVariantTwo />}
        </StructuredContentProvider>
      </SegmentTracking>
    </React.Fragment>
  );
};

export default FrontPageMainWrapper;
