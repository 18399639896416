'use client';
/**
 * Dependencies
 */
import React from 'react';
import ShoppingModule from '~/app/components/front-page-redesign/shopping-module';
import NerdWalletPlus from '~/app/components/front-page-redesign/nerdwallet-plus/nerdwallet-plus';
import SocialModule from '~/app/components/front-page-redesign/social-module/social-module';
import { Box, CenteredContainer, Text } from '@nerdwallet/currency';
import styles from './front-page-control.module.scss';
import Quiz from '~/app/components/front-page-redesign/quiz';
import Testimonials from '~/app/components/front-page-redesign/testimonials';
import Articles from '~/app/components/front-page-redesign/articles';

const FrontPageVariantTwo = () => {
  return (
    <Box className={styles.mainWrapper}>
      <CenteredContainer className={styles.shoppingModuleWithoutBanner}>
        <div className={styles.alternativeHeading}>
          <div className={styles.headingTitle}>
            <Text component="h1" bold>
              <span className={styles.titleSize}>
                Letting NerdWallet do the work for you is genius
              </span>
            </Text>
          </div>
          <div className={styles.emptyBox} />
        </div>
      </CenteredContainer>
      <Quiz isVariantTwo />
      <CenteredContainer className={styles.titleWrapper}>
        <div className={styles.alternativeSubHeading}>
          <Text component="h2" size="large">
            Compare our top picks side by side. Click a tab to get started.
          </Text>
        </div>
        <ShoppingModule />
      </CenteredContainer>
      <Articles alternativeVersion />
      <NerdWalletPlus showNerdWalletPlus showBestOfAwards={false} />
      <SocialModule />
      <Testimonials backgroundColor="#00482F" negativeTopMarginValue={53} />
    </Box>
  );
};

export default FrontPageVariantTwo;
