/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Cookies from 'universal-cookie';
import Image from 'next/image';
import { Button, CenteredContainer, Link } from '@nerdwallet/currency';
import LinkGenerator, { AuthParams } from '@nerdwallet/nw-auth-links';
import { gql, useQuery } from '@nerdwallet/apollo';

import { BASE_IMAGE_URL, BEST_OF_AWARDS_2025_URL } from '../constants';
import FolderTabContainer from '../folder-tab-container/folder-tab-container';
import styles from './nerdwallet-plus.module.scss';
import SegmentImpression from '~/app/components/segment-impression';
import { HOMEPAGE_SEGMENT_INSTRUMENTATION } from '~/app/lib/analytics/analytics-constants';
import useAnalytics from '~/app/lib/segment/useAnalytics';
import appConfig from '@nerdwallet/app-config';
import usePageViewId from '~/app/lib/segment/usePageViewId';
import { COPY, FOLDER_COLORS } from './constants';

const PAGE_TYPE: keyof typeof COPY = 'TSY_HOUSE_AD';

interface Props {
  showNerdWalletPlus?: boolean;
  showBestOfAwards?: boolean;
}

interface AuthLinksParams extends AuthParams {
  isNWPlusLegalLinkCopy?: string;
  loginHeading?: string;
  loginCta?: string;
  loginHeroHref?: string;
  loginHeroStyles?: string;
  registrationHeading?: string;
  registrationCta?: string;
  registrationHeroHref?: string;
  registrationHeroStyles?: string;
}

export const GET_TREASURY_RATES_QUERY = gql`
  query GetTreasuryAccountYTM {
    treasuryAccountMarketing {
      annualPercentageYield
      lastFetchedAt
    }
  }
`;


const NerdWalletPlusContainer = ({
  showNerdWalletPlus = false,
  showBestOfAwards = false,
}: Props): JSX.Element | null => {
  const { data } = useQuery(GET_TREASURY_RATES_QUERY)
  const { trackSegmentTrackEvent } = useAnalytics();
  const pageViewId = usePageViewId();
  const cookies = new Cookies();

  if (!showNerdWalletPlus && !showBestOfAwards) return null;

  const auth0Params: AuthLinksParams = {
    redirectTo: `${appConfig?.SITE_BASE_URL}/home/dashboard/home`,

    // Tracking Params
    driverPageName: 'homepage',
    driverLocation: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.location,
    driverSubLocation: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.sub_location,
    driverElement: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.entity_name,
    deployableName: appConfig?._meta?.deployable,
    deployableVersion: appConfig?._meta?.version,
    driverPageUrl: appConfig?.SITE_BASE_URL,
    driverPageViewId: pageViewId,
    driverContentId: '',
    driverContentSource: '',
    driverFlowName: '',
    anonymousUserId: cookies.get('ajs_anonymous_id'),
    mostRecentTraditionalDeviceId: cookies.get('cookie_id'),

    // Flow design params
    isNWPlusLegalLinkCopy: 'true',
    loginHeading: "Let's start by signing in to your NerdWallet account",
    loginCta: 'SIGN IN',
    loginHeroHref:
      'https://www.nerdwallet.com/cdn/nerdwallet-plus/onboarding/sb-auth-hero-collect.png',
    loginHeroStyles: JSON.stringify({
      alignX: 'center',
    }),
    registrationHeading: 'Register to get started',
    registrationCta: 'CREATE ACCOUNT',
    registrationHeroHref:
      'https://www.nerdwallet.com/cdn/nerdwallet-plus/onboarding/sb-auth-hero-collect.png',
    registrationHeroStyles: JSON.stringify({
      alignX: 'center',
    }),
  };

  const linkGenerator = new LinkGenerator();
  

  const handleNerdWalletPlusAnalytics = () => {
    trackSegmentTrackEvent(
      'Element Interaction',
      HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign,
    );
  };

  const handleBoaAnalytics = () => {
    trackSegmentTrackEvent('Element Interaction', {
      location: 'homepage',
      sub_location: 'boa_page',
      entity_name: '2025_boa_banner_click',
    });
  };
  
  const tsyHouseAdUrl = `${appConfig?.SITE_BASE_URL}/lp/treasury-account`;
  const registerUrl = PAGE_TYPE === 'TSY_HOUSE_AD' ? tsyHouseAdUrl : linkGenerator.register(auth0Params);

  const folderBgColor = FOLDER_COLORS[PAGE_TYPE];
  const {title, subtitle, ctaCopy, terms, images} = COPY[PAGE_TYPE];

  return (
    <div className={styles.container}>
      <FolderTabContainer
        tabBackgroundColor="#008254"
        contentBackgroundColor="#008254"
        folderTabWidthPercentage={15}
      >
        <CenteredContainer className={styles.innerContainer}>
          {showNerdWalletPlus && (
            <SegmentImpression
              eventName="Element Impression"
              eventProps={{
                entity_name:
                  HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.entity_name,
                location: HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.location,
                sub_location:
                  HOMEPAGE_SEGMENT_INSTRUMENTATION.campaign.sub_location,
              }}
            >
              <div className={styles.firstRow}>
                <div className={styles.folderContainer}>
                  <FolderTabContainer
                    isFolderBackground
                    hideOnMobile
                    smallCorners
                    folderTabWidthPercentage={40}
                  >
                    <div style={{ height: 40 }} />
                  </FolderTabContainer>
                  <FolderTabContainer
                    tabBackgroundColor={folderBgColor}
                    contentBackgroundColor={folderBgColor}
                    folderTabWidthPercentage={40}
                    smallCorners
                  >
                    <div className={styles.folderContent}>
                      <span className={styles.folderMainText}>
                        {typeof title === 'function' ? title(data?.treasuryAccountMarketing?.annualPercentageYield) : title}
                      </span>
                      <span className={styles.folderSubtext}>
                        {subtitle}
                      </span>
                      <div className={styles.folderCTA}>
                        <Button
                          primary
                          onClick={() => handleNerdWalletPlusAnalytics()}
                          href={registerUrl}
                        >
                          {ctaCopy}
                        </Button>
                      </div>
                      <span className={styles.nwPlusTerms}>
                        {typeof terms === 'function' ? terms(data?.treasuryAccountMarketing?.lastFetchedAt) : terms}
                      </span>
                    </div>
                  </FolderTabContainer>
                </div>
                <Link
                  href={registerUrl}
                  onClick={() => handleNerdWalletPlusAnalytics()}
                  className={styles.smartphoneDesktopImage}
                >
                  <Image
                    src={images.desktop.src}
                    alt="Cellphone showing NerdWallet app"
                    width={images.desktop.width}
                    height={images.desktop.height}
                  />
                </Link>
                <Link
                  href={registerUrl}
                  onClick={() => handleNerdWalletPlusAnalytics()}
                  className={styles.smartphoneTabletImage}
                >
                  <Image
                    src={images.tablet.src}
                    alt="Cellphone showing NerdWallet app"
                    width={images.tablet.width}
                    height={images.tablet.height}
                  />
                </Link>
                <Link
                  href={registerUrl}
                  onClick={() => handleNerdWalletPlusAnalytics()}
                  className={styles.smartphoneMobileImage}
                >
                  <Image
                    src={images.mobile.src}
                    alt="Cellphone showing NerdWallet app"
                    width={images.mobile.width}
                    height={images.mobile.height}
                  />
                </Link>
              </div>
            </SegmentImpression>
          )}
          {showBestOfAwards && (
            <SegmentImpression
              eventName="Element Impression"
              eventProps={{
                location: 'homepage',
                sub_location: 'boa_page',
                entity_name: '2025_boa_banner',
              }}
            >
              <div className={styles.secondRow}>
                <div className={styles.boa2025Background}>
                  <Link
                    href={BEST_OF_AWARDS_2025_URL}
                    onClick={() => handleBoaAnalytics()}
                    className={styles.boa2025DesktopImage}
                  >
                    <Image
                      src={`${BASE_IMAGE_URL}/heroes/boa-1440-2x.png`}
                      alt="NerdWallet experts analyzing the best-of awards 2025"
                      width={581}
                      height={445}
                    />
                  </Link>
                </div>
                <Link
                  href={BEST_OF_AWARDS_2025_URL}
                  onClick={() => handleBoaAnalytics()}
                  className={styles.boa2025TabletImage}
                >
                  <Image
                    src={`${BASE_IMAGE_URL}/heroes/boa-768-2x.png`}
                    alt="NerdWallet experts analyzing the best-of awards 2025"
                    width={320}
                    height={270}
                  />
                </Link>
                <Link
                  href={BEST_OF_AWARDS_2025_URL}
                  onClick={() => handleBoaAnalytics()}
                  className={styles.boa2025MobileImage}
                >
                  <Image
                    src={`${BASE_IMAGE_URL}/heroes/boa-375-2x.png`}
                    alt="NerdWallet experts analyzing the best-of awards 2025"
                    width={343}
                    height={320}
                  />
                </Link>
                <div className={styles.folderContainer}>
                  <FolderTabContainer
                    isFolderBackground
                    hideOnMobile
                    smallCorners
                    folderTabWidthPercentage={40}
                  >
                    <div style={{ height: 40 }} />
                  </FolderTabContainer>
                  <FolderTabContainer
                    tabBackgroundColor="#B7ECDA"
                    contentBackgroundColor="#B7ECDA"
                    folderTabWidthPercentage={40}
                    smallCorners
                  >
                    <div className={styles.folderContent}>
                      <span className={styles.folderMainText}>
                        Discover the best of the best financial products of 2025
                      </span>
                      <span className={styles.folderSubtext}>
                        Our Nerds narrowed down 1,000+ credit cards, savings
                        accounts, and more to just one winner per category — so
                        all you have to do is apply.
                      </span>
                      <div className={styles.folderCTA}>
                        <Button
                          primary
                          onClick={() => handleBoaAnalytics()}
                          href={BEST_OF_AWARDS_2025_URL}
                        >
                          SEE THE WINNERS
                        </Button>
                      </div>
                    </div>
                  </FolderTabContainer>
                </div>
              </div>
            </SegmentImpression>
          )}
        </CenteredContainer>
      </FolderTabContainer>
    </div>
  );
};

export default NerdWalletPlusContainer;
