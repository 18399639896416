'use client';
/**
 * Dependencies
 */
import React from 'react';
import ShoppingModule from '~/app/components/front-page-redesign/shopping-module';
import NerdWalletPlus from '~/app/components/front-page-redesign/nerdwallet-plus/nerdwallet-plus';
import SocialModule from '~/app/components/front-page-redesign/social-module/social-module';
import { Box, CenteredContainer, Text } from '@nerdwallet/currency';
import styles from './front-page-control.module.scss';
import Quiz from '~/app/components/front-page-redesign/quiz';
import Testimonials from '~/app/components/front-page-redesign/testimonials';
import Articles from '~/app/components/front-page-redesign/articles';

const FrontPageVariantOne = () => {
  return (
    <Box className={styles.mainWrapper}>
      <CenteredContainer className={styles.shoppingModuleWithoutBanner}>
        <Box display="flex" flexDirection="column">
          <Box className={styles.heading}>
            <Text component="h1" bold>
              <span className={styles.titleSize}>
                Letting NerdWallet do the work for you is genius
              </span>
            </Text>
            <div className={styles.subHeading}>
              <Text component="h2" size="large">
                Compare our top picks side by side. Click a tab to get started.
              </Text>
            </div>
          </Box>
          <ShoppingModule isVariantOne />
        </Box>
      </CenteredContainer>
      <Quiz mobileBackground="#008254" />
      <Articles alternativeVersion />
      <NerdWalletPlus showNerdWalletPlus showBestOfAwards={false} />
      <SocialModule />
      <Testimonials backgroundColor="#00482F" negativeTopMarginValue={53} />
    </Box>
  );
};

export default FrontPageVariantOne;
